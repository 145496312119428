import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Application from "../components/Application/Application";
import Carousel from "../components/Carousel/Carousel";
import { CategoriesData } from "../components/Categories/CategoriesData";
import Contacts from "../components/Contacts/Contacts";
import Title from "../components/UI/Title/Title";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";
import "../styles/App.css";
import Button from "../components/UI/Button/Button";
import divavid from "../videos/DIVA.mp4"
import siyaivid from "../videos/SIYAI.mp4"
import discovid from "../videos/DISCO.mp4"
const CategoriesPage = () => {
  const params = useParams();
  const router = useNavigate();
  const info = CategoriesData.find((el) => el.name === params.name);
  console.log(info.name);
  return (
    <div>
      <Title style={{ margin: "10px auto" }}>{info.name}</Title>
      <div className="categoriesText">{info.description}</div>
      {info.name === "Шоу-программа" ? (
        <div className="butCateg">
          <Button onClick={() => router(`/dances`)}>Все номера</Button>
        </div>
      ) : (
        <></>
      )}
      {(info.name === "ДИАНА КОХ & NOGI") ? (
        <div className="player">
          <VideoPlayer video={divavid} />
          <VideoPlayer video={siyaivid} />
          <VideoPlayer video={discovid} />
        </div>
      ) : (Object.values(info.video).length > 0) ? (
        <div className="player">
          <VideoPlayer video={Object.values(info.video)[0]} />
        </div>
      ) : (
        <div className="player">
          <VideoPlayer video={Object.values(info.video)[0]} />
        </div>
      )}
      {(info.name === "ДИАНА КОХ & NOGI" || info.name === "INTRO SHOW") ? (
        <></>
      ) : (
        <>
          <Title>Фото c мероприятий</Title>
          <div className="carousel">
            <Carousel carous={info.carousel} />
          </div>
        </>
      )}

      <Title>Оставьте заявку</Title>
      <Application />
      <Contacts />
    </div>
  );

};

export default CategoriesPage;
