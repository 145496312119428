import React from "react";
import s from "./Slide.module.css";
const Slide = (props) => {
  return (
    <div className={s.image}>
      <img src={props.image} alt="" />
    </div>
  );
};

export default Slide;
