import React from 'react';
import 'react-html5video/dist/styles.css';
import ReactPlayer from 'react-player/file';
const VideoPlayer = (props) => {
  
  const mediaQuerry = window.matchMedia("(max-width: 380px)");
  const mediaQuerry2 = window.matchMedia("(max-width: 580px)");
  let settings = {};
  if (mediaQuerry.matches && mediaQuerry2.matches) {
    settings = {
      playing: props.playing,
      controls: true,
      url: props.video,
      height: "50vh",
      width: "100%",
      loop: true,
      volume: null,
      playsinline: true,
      pip: true
    };
  } else if (!mediaQuerry.matches && mediaQuerry2.matches) {
    settings = {
      playing: props.playing,
      controls: true,
      url: props.video,
      height: "75vh",
      width: "100%",
      loop: true,
      volume: null,
      playsinline: true,
      pip: true,
    };
  } else {
    settings = {
      playing: props.playing,
      controls: true,
      url: props.video,
      height: "90vh",
      width: "100%",
      loop: true,
      volume: null,
      playsinline: true,
      pip: true,
    };
  }
  return (
    <div>
      <ReactPlayer {...settings}/>
    </div>
  );
}

export default VideoPlayer