import React from "react";
import s from "./CategoriesCard.module.css";
import { useNavigate } from "react-router-dom";
import nogiPhoto from "../../images/nogiPHOTO.jpg";
import nogiLogo from "../../images/nogiManyLOGO.jpg";

const CategoriesCard = (props) => {
  const router = useNavigate();
  let back = props.id % 2 === 0 ? {nogiLogo} : {nogiPhoto}
  return (
    <div
      onClick={() => router(`/categories/${props.name}`)}
      className={s.danceCard}
    >
      <img className={s.image} src={Object.values(back)} alt="" />
      <div className={s.container}>
        <p className={s.name}> {props.name} </p>
      </div>
    </div>
  );
};

export default CategoriesCard;
