import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import s from "./Carousel.module.css";
import Slide from "./Slide/Slide";
const Carousel = (props) => {

  const mediaQuerry = window.matchMedia("(max-width: 520px)");
  const mediaQuerry2 = window.matchMedia("(max-width: 800px)");
  let settings = {}
  
  if (mediaQuerry.matches && mediaQuerry2.matches) {
    settings = {
      dots: true,
      infinite: true,
      speed: 500,
      
      slidesToShow: 1,
      slidesToScroll: 1,
      className: s.carou,
      adaptiveHeight: true,
    };
  } else if (!mediaQuerry.matches && mediaQuerry2.matches) {
    settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      className: s.carou,

    };
  } else {
    settings = {
      dots: true,
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      className: s.carou,
    };
  }
  
  return (
    <div>
      <Slider {...settings}>
        {props.carous.map((slide) => (
          <Slide className={s.slide} image={slide} />
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
