import React from 'react';
import { CategoriesData } from "./CategoriesData";
import CategoriesCard from "./CategoriesCard"
import s from "./Categories.module.css";
const Categories = () => {
  return (
    <div className={s.dancesCards}>
      {CategoriesData.map((dance) => (
        <CategoriesCard key = {dance.id} description = {dance.description} name={dance.name} image={dance.image} id = {dance.id}/>
      ))}
    </div>
  );
};

export default Categories