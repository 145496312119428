import React from 'react'
import Title from '../components/UI/Title/Title'
import ContactsBlock from '../components/Contacts/ContactsBlock'

const ContactsPage = () => {
  return (
    <div>
      <Title>Напишите нам</Title>
      <ContactsBlock />
    </div>
  );
}

export default ContactsPage