import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logosquare.png";
import Ticker from "../UI/Ticker/Ticker";
import s from "./Navbar.module.css";

const Navbar = () => {
  const router = useNavigate();

  const [burgerClass, setBurgerClass] = useState(`${s.burgerBar} ${s.unclicked}`);
  const [navbarClass, setNavbarClass] = useState(`${s.navbar} ${s.hidden}`);
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [isNavbarShow, setIsNavbarShow] = useState(false);

   const updateMenu = () => {
     if (!isMenuClicked || !isNavbarShow) {
       setNavbarClass(`${s.navbar} ${s.hidden}`);
       setBurgerClass(`${s.burgerBar} ${s.unclicked}`);
       setIsMenuClicked(!isMenuClicked);
       setIsNavbarShow(!isNavbarShow)
     } else if (isMenuClicked || isNavbarShow) { 
       setNavbarClass(`${s.navbar} ${s.active}`);
       setBurgerClass(`${s.burgerBar} ${s.clicked}`);
       setIsMenuClicked(!isMenuClicked);
       setIsNavbarShow(!isNavbarShow);
     }
   };
  const mediaQuerry = window.matchMedia("(max-width: 480px)");
  return (
    <div className={s.wrapp}>
      {mediaQuerry.matches ? (
        <img
          onClick={() => router(`/main`)}
          src={logo}
          alt=""
          className={s.logo}
        />
      ) : (
        <div></div>
      )}

      <nav className={navbarClass}>
        <Link onClick={() => updateMenu()} to="/main">
          Главная
        </Link>
        <Link onClick={() => updateMenu()} to="/dances">
          Танцы
        </Link>
        <Link onClick={() => updateMenu()} to="/contacts">
          Контакты
        </Link>
        <Link onClick={() => updateMenu()} to="/application">
          Оставьте заявку
        </Link>
      </nav>
      <div className={burgerClass} onClick={() => updateMenu()}>
        <span className={s.line1}></span>
        <span className={s.line2}></span>
        <span className={s.line3}></span>
      </div>
      <Ticker />
    </div>
  );
};

export default Navbar;
