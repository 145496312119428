import React from "react";
import Application from "../components/Application/Application";
import Title from "../components/UI/Title/Title";
import '../styles/App.css';

const ApplicationPage = () => {


  return (
    <div className="applic">
      <Title style={{ background: "transparent" }} text="">
        Оставьте заявку
      </Title>
      <Application
      />
    </div>
  );
};

export default ApplicationPage;
