import React from "react";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import './styles/App.css'
import AppRouter from "./components/AppRouter.jsx";
import ScrollToTop from "./routers/ScrollToTop";
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
