import React from "react";
import s from "./Ticker.module.css";
import logo from '../../../images/nogiRUN.jpg'
const Ticker = () => {
  console.log(logo);
  return (
    <div className={s.line}>
      <div className={s.lineInner}>
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />

        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />

        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />

        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
        <img className={s.lineText} src={logo} alt="" />
      </div>
    </div>
  );
};

export default Ticker;