import React from 'react'
import s from './Contacts.module.css'
const Contacts = () => {
  return (
    <div className={s.cont}>
      <a href="https://ekaterinburg.artist.ru/NOGI_Dance_Project/" target="_blank" rel = 'noreferrer'> artist </a>
      <a href="https://t.me/AngelinaPeiko" target="_blank" rel = 'noreferrer'> telegram </a>
      <a href="https://instagram.com/nogi_tantsuyut?igshid=NGVhN2U2NjQ0Yg==" target="_blank" rel = 'noreferrer'> inst </a>
      <a href="https://vk.com/club211237682" target="_blank" rel = 'noreferrer'> vk </a>
      <a href="https://wa.me/+79122150158" target="_blank" rel = 'noreferrer'> whatsapp </a>
    </div>
  );
}

export default Contacts