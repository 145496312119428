import nogiPhoto from "../../images/nogiPHOTO.jpg";
import nogiLogo from "../../images/nogiManyLOGO.jpg";

import gogovid from "../../videos/IMG_2285.mp4"
import Welcome from "../../videos/WELCOME.mp4";
import supportvid from "../../videos/support.mp4"
import showprogvid from "../../videos/PROMO.mp4"
import performancevid from "../../videos/Performance.mp4"
import siyaivid from "../../videos/SIYAI.mp4"
import introvid from "../../videos/INTRO.mp4"

import carous1 from '../../images/Performance/IMG_4992.jpg'
import carous2 from '../../images/Performance/IMG_4993.jpg'
import carous3 from "../../images/Performance/IMG_4994.jpg";
import carous4 from "../../images/Performance/IMG_4995.jpg";
import carous5 from "../../images/Performance/IMG_4996.jpg";
import carous6 from "../../images/Performance/IMG_8046.JPG";
import carous7 from "../../images/Performance/IMG_8047.JPG";
import carous8 from "../../images/Performance/IMG_8049.JPG";
import carous9 from "../../images/Performance/IMG_8050.JPG";


import artisti1 from "../../images/Sartistami/IMG_3676.jpg";
import artisti2 from "../../images/Sartistami/IMG_3677.JPG";
import artisti3 from "../../images/Sartistami/IMG_3678.JPG";
import artisti4 from "../../images/Sartistami/IMG_3679.JPG";
import artisti5 from "../../images/Sartistami/IMG_3680.JPG";
import artisti6 from "../../images/Sartistami/IMG_3681.JPG";
import artisti7 from "../../images/Sartistami/IMG_3682.JPG";
import artisti8 from "../../images/Sartistami/IMG_3683.JPG";
import artisti9 from "../../images/Sartistami/IMG_9519.JPG";
import artisti10 from "../../images/Sartistami/IMG_9543.jpg";
import artisti11 from "../../images/Sartistami/IMG_9545.jpg";
import artisti12 from "../../images/Sartistami/IMG_9546.jpg";
import artisti13 from "../../images/Sartistami/IMG_8884.JPG";
import artisti14 from "../../images/Sartistami/IMG_8895.JPG";
import artisti15 from "../../images/Sartistami/IMG_8995.JPEG";
import artisti16 from "../../images/Sartistami/IMG_8993.JPEG";
import artisti17 from "../../images/Sartistami/IMG_8994.JPEG";
import artisti18 from "../../images/Sartistami/IMG_9001.PNG";
import artisti19 from "../../images/Sartistami/IMG_9002.PNG";
import artisti20 from "../../images/Sartistami/IMG_8997.PNG";
import artisti21 from "../../images/Sartistami/IMG_8998.PNG";
import artisti22 from "../../images/Sartistami/IMG_8999.PNG";


import gogo1 from "../../images/GO-GO/IMG_2339.jpeg";
import gogo2 from "../../images/GO-GO/IMG_2340.jpeg";
import gogo3 from "../../images/GO-GO/IMG_2342.jpg";
import gogo4 from "../../images/GO-GO/IMG_2345.jpeg";
import gogo5 from "../../images/GO-GO/IMG_2356.jpg";
import gogo6 from "../../images/GO-GO/IMG_2360.jpeg";
import gogo7 from "../../images/GO-GO/IMG_2375.jpeg";
import gogo8 from "../../images/GO-GO/IMG_2412.JPG";
import gogo9 from "../../images/GO-GO/IMG_2413.jpeg";

import welcome1 from "../../images/Welcome/C1C968B5-6F74-48BF-86FF-0DA5513539BD.jpg";
import welcome2 from "../../images/Welcome/IMG_3667.JPEG";
import welcome3 from "../../images/Welcome/IMG_3668.JPEG";
import welcome4 from "../../images/Welcome/IMG_3669.jpg";
import welcome5 from "../../images/Welcome/IMG_3670.PNG";
import welcome6 from "../../images/Welcome/IMG_3672.jpg";
import welcome7 from "../../images/Welcome/IMG_3673.jpg";

import vistup1 from "../../images/svistup/IMG_0553.JPG"
import vistup2 from "../../images/svistup/IMG_2887.jpeg";
import vistup3 from "../../images/svistup/IMG_2892_1.jpeg";
import vistup4 from "../../images/svistup/IMG_2907.jpeg";
import vistup5 from "../../images/svistup/IMG_3107.jpeg";
import vistup6 from "../../images/svistup/IMG_3124.jpeg";
import vistup7 from "../../images/svistup/IMG_3125.jpeg";
import vistup8 from "../../images/svistup/IMG_3203.jpeg";
import vistup9 from "../../images/svistup/IMG_3328.png";
import vistup10 from "../../images/svistup/IMG_3602.jpeg";
import vistup11 from "../../images/svistup/IMG_3652.JPEG";
import vistup12 from "../../images/svistup/IMG_3653.jpeg";
import vistup13 from "../../images/svistup/IMG_3654.jpeg";
import vistup14 from "../../images/svistup/IMG_3655.JPEG";
import vistup15 from "../../images/svistup/IMG_3656.jpeg";
import vistup16 from "../../images/svistup/IMG_3657.JPEG";
import vistup17 from "../../images/svistup/IMG_3659.jpeg";
import vistup18 from "../../images/svistup/IMG_3660.JPEG";
import vistup19 from "../../images/svistup/IMG_3662.JPEG";
import vistup20 from "../../images/svistup/IMG_3664.jpeg";
import vistup21 from "../../images/svistup/IMG_3666.JPEG";
import vistup22 from "../../images/svistup/IMG_3671.jpeg";

import group1 from "../../images/group/photo1700070261.jpeg"
import group2 from "../../images/group/IMG_0522.jpg";
import group3 from "../../images/group/IMG_8483.JPG";

import proch1 from '../../images/prochee/IMG_3690.JPG'
import proch2 from "../../images/prochee/IMG_3691.jpg";
import proch3 from "../../images/prochee/IMG_3692.jpg";
import proch4 from "../../images/prochee/IMG_3693.jpg";
import proch5 from "../../images/prochee/IMG_3698.JPG";
import proch6 from "../../images/prochee/IMG_3699.JPG";
import proch7 from "../../images/prochee/IMG_3700.JPG";
import proch8 from "../../images/prochee/IMG_3701.JPG";
import proch9 from "../../images/prochee/IMG_3702.JPG";
import proch10 from "../../images/prochee/IMG_3703.jpg";
export const CategoriesData = [  
  {
  id: 8,
  name: "INTRO SHOW",
  image: { nogiLogo },
  carousel: [],
  description:
    "ПРЕМЬЕРА ❗️ NEW \n INTRO SHOW \n Уникальное танцевально-световое шоу by NOGI & U-FLOW \n Первое впечатление всегда задает настроение, поэтому так важно, чтобы открытие мероприятия было ярким, запоминающимся, вызывало эмоцию у зрителя. Мы создали то, что удовлетворит все эти критерии - INTRO SHOW\n В идейной основе номера - символ наступающего 2025 года - змея 🐍 \n Наш зритель - самый главный приоритет! Каждый раз мы придумываем что-то новое, необычное, яркое, запоминающееся. И INTRO SHOW - не исключение. Сочетание костюмов, музыки, света, движений точно не оставит вас равнодушными.\n P.S. Уважаемые организаторы и заказчики, вы целиком и полностью снимаете с себя задачу по эффектному открытию вашего мероприятия  остается лишь наблюдать за происходящим и наслаждаться \n ✅ Длительность шоу - 5,5 минут \n ✅ Количество танцоров - от 4 до 7 (И да, теперь у нас есть парни в составе) \n ✅ В номере используется профессиональное световое оборудование",
  video: { introvid },
},
{
  id: 9,
  name: "ДИАНА КОХ & NOGI",
  image: { nogiPhoto },
  carousel: [],
  description:
    "NEW | Вокально-танцевальный проект \nПредлагаем вашему вниманию 3 совершенно разных по стилю, но одинаково атмосферных шоу-блока:\n 1. «СИЯЙ» Популярные русскоязычные треки известных артистов - Зиверт, Анна Асти, Моя Мишель и др., а также авторский одноименный трек Дианы - Сияй ✨\n Неповторимые блестящие образы, стильная подача и современная хореография станут дополнением и оставят яркое послевкусие \n 2. «DIVA» Самые известные и зажигательные треки зарубежных поп-див: BEYONCÉ, J LO, RIHANNA, MARUV ✨\n Окунитесь в атмосферу шика, страз, блеска, словно Вы попали на концерт этих мировых звезд \n 3. «DISCO» Блок для тех, кто точно знает такие треки, как «Тополиный пух», «Музыка нас связала», «Ночь» Андрея Губина 🔥 А мы уверены, что знает эти треки абсолютно каждый 🙌🏻 \n Ожившие образы 80х и 90х годов - неон, джинса и зажигательные танцы в сопровождении потрясающего вокала от Дианы КОХ",
  video: { siyaivid },
},
  {
    id: 1,
    name: "Шоу-программа",
    image: { nogiLogo },
    carousel: [
      vistup1,
      vistup2,
      vistup3,
      vistup4,
      vistup5,
      vistup6,
      vistup7,
      vistup8,
      vistup9,
      vistup10,
      vistup11,
      vistup12,
      vistup13,
      vistup14,
      vistup15,
      vistup16,
      vistup17,
      vistup18,
      vistup19,
      vistup20,
      vistup21,
      vistup22,
    ],
    description:
      "NOGI | про стиль и современное видение шоу-балета в целом. \nВ нашем репертуаре большая часть постановок - современные, стильные образы и постановки от лучших хореографов нашей страны; \nНо также есть и эстрадный блок для тех, кто не готов переходить к новому.\n  NOGI танцуют:\n  ▪️ современную хореографию для особо искушённого зрителя (шоу в различных танцевальных стилях - High Heels, Vogue и другие)\n ▪️ шоу более приземлённые, но не менее эффектные (бразильский карнавал, Кан-Кан, русский народный)\n  Состав участниц от 3 до 15 человек Программа от Vogue до Кан-Кана.\n Выбирай образы, остальное - за нами.",
    video: { showprogvid },
  },
  {
    id: 2,
    name: "Go-Go",
    image: { nogiPhoto },
    carousel: [gogo1, gogo2, gogo3, gogo4, gogo5, gogo6, gogo7, gogo8, gogo9],
    description:
      "Танцевальное сопровождение вашего мероприятия до самого конца.\n Не оставляем возможности гостям не танцевать вместе с нами.\n Сопровождали следующие крупнейшие музыкальные фестивали:\n - TRANCEMISSION |SOUNGARDEN Крупнейший фестиваль электронной музыки в России\n - Легендарный Drum&Bass спектакль «Пиратская станция» | \nAtlantis \n Работали на одной площадке с известными диджеями: DJ FEEL\n Alexander Popov\n Roman Messer\n Ruslan Radriges\n Eximinds Somnia\n Tycoos\n PAPAKLEY",
    video: { gogovid },
  },
  {
    id: 3,
    name: "Performance",
    carousel: [
      carous1,
      carous2,
      carous3,
      carous4,
      carous5,
      carous6,
      carous7,
      carous8,
      carous9,
    ],
    image: { nogiLogo },
    description:
      "NOGI | мы за творческий подход. Создадим образ с нуля под ключ.\n Один из популярных наших образов - Art Perfomance «ETHNO» \nЕсли в нашем репертуаре нет номера или образа, который Вам нужен, мы создадим его вместе с Вами! Потому что это - творчество, а творить и вытворять – наше всё! И мы создадим то шоу, которое Вам понравится ",
    video: { performancevid },
  },
  {
    id: 4,
    name: "Welcome",
    image: { nogiPhoto },
    carousel: [
      welcome1,
      welcome2,
      welcome3,
      welcome4,
      welcome5,
      welcome6,
      welcome7,
    ],
    description:
      "Создадим вау-эффект уже в момент сбора гостей на мероприятии. \n Фотозона при входе, ожившие образы под вашу тематику, а также сервис-группа (помощь при регистрации гостей, официальные награждения)",
    video: { Welcome },
  },
  {
    id: 5,
    name: "Работа с артистами",
    image: { nogiLogo },
    carousel: [
      artisti1,
      artisti2,
      artisti3,
      artisti4,
      artisti5,
      artisti6,
      artisti7,
      artisti8,
      artisti9,
      artisti10,
      artisti11,
      artisti12,
      artisti13,
      artisti14,
      artisti15,
      artisti16,
      artisti17,
      artisti18,
      artisti19,
      artisti20,
      artisti21,
      artisti22,
    ],
    description:
      "Коллаборации с действующими артистами, подтанцовки для вокалистов. Хочешь, чтобы твое выступление заиграло новыми красками и эмоциями - оставляй заявку за подробностями.\n Совместные проекты:\n - Диана Кох \n - U-FLOW (световое шоу)",
    video: {},
  },
  {
    id: 6,
    name: "Группа поддержки",
    image: { nogiLogo },
    carousel: [group1, group2, group3],
    description:
      "Выступления на большой состав участниц до 15 человек. Использование спортивной атрибутики - пипидастры (махнушки), флаги, боксерские перчатки и многое другое.\nСопровождали крупные спортивные мероприятия в Екатеринбурге:\n- БОКСЗАБЕГ 2023\n- КОРПОРАТИВНЫЕ ИГРЫ УРФО 2021, 2022 \n- выступления на баскетбольных матчах",
    video: { supportvid },
  },
  {
    id: 7,
    name: "Прочее",
    image: { nogiPhoto },
    carousel: [
      proch1,
      proch2,
      proch3,
      proch4,
      proch5,
      proch6,
      proch7,
      proch8,
      proch9,
      proch10,
    ],
    description:
      "Аренда WELCOME-зоны \n Первые в Екатеринбурге представили фотозону в виде огромной стены, из которой Вашим гостям будет предложен приветственный бокал игристого. Также возможно оживить стену дополнительно - настоящие красивые ноги в ярких сапогах или любой другой обуви.",
    video: { Welcome },
  },

];
