import React from 'react';
import DanceCard from '../DanceCard/DanceCard';
import { DancesData } from '../DancesData';
import s from "./DancesBlock.module.css";
const DancesBlock = () => {
  
  return (
    <div className={s.block}>
      {DancesData.map((dance) => (
        <DanceCard
          name={dance.name}
          new = {dance.new}
          image={dance.photo}
          video={dance.video}
          description={dance.desription}
          key={dance.id}
        />
      ))}
    </div>
  );
}

export default DancesBlock