import pussycat from "../../images/pussycat.jpg";
import asia from "../../images/Asia.jpg";
import cancan from "../../images/CAN-CAN.jpg";
import rio from "../../images/RIO.jpg";
import maruv from "../../images/MARUV.JPG";
import bumpit from "../../images/BUMPIT.jpg";
import russianshow from "../../images/RUSSIANSHOW.jpeg";
import chocolata from "../../images/CHOCN.JPG";
import feelinggood from "../../images/FG.jpg";
import masarriba from "../../images/MAN.JPG";
import frozen from "../../images/FROZEN.jpg";
import nogi from "../../images/NOGI.jpg";
import neon from "../../images/NEON.jpg";
import desire from "../../images/DESIRE.jpg";
import diva from "../../images/DIVA.jpg";
import disco from "../../images/DISCO.jpg";
import dragon from "../../images/DRAGON.JPEG";
import intro from "../../images/INTRO.jpg";
import siyai from "../../images/SIYAI.JPG";
import heels from "../../images/HEELS.jpg"

import russianshowvid from "../../videos/RUSSIANSHOW.mp4";
import bumpitvid from "../../videos/BUMPIT.mp4";
import maruvvid from "../../videos/MARUV.mp4";
import cancanvid from "../../videos/CANCAN.mp4";
import riovid from "../../videos/RIO.mp4";
import nogivid from "../../videos/NOGI.mp4";
import asiavid from "../../videos/ASIA.mp4";
import pussycatvid from "../../videos/PUSYCAT.mp4";
import frozenvid from "../../videos/FROZEN.mp4";
import fgvid from "../../videos/FG.mp4";
import masarribavid from "../../videos/MASARRIBA.mp4";
import neonvid from "../../videos/NEON.mp4"
import desirevid from "../../videos/DESIRE.mp4"
import chocolatavid from "../../videos/CHOCOLATA.mp4"
import divavid from "../../videos/DIVA.mp4"
import dragonvid from "../../videos/DRAGON.mp4"
import siyaivid from "../../videos/SIYAI.mp4"
import introvid from "../../videos/INTRO.mp4"
import discovid from "../../videos/DISCO.mp4"
import heelsvid from "../../videos/HEELS.mp4"
export const DancesData = [
  
  {
    id: 15,
    name: "INTRO",
    new: true,
    photo: { intro },
    video: { introvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 20,
    name: "HEELS",
    new: true,
    photo: { heels },
    video: { heelsvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 10,

    name: "CHOCOLATA",
    photo: { chocolata },
    video: { chocolatavid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 3,
    name: "FROZEN",
    photo: { frozen },
    video: { frozenvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 1,
    name: "Mas Arriba",
    photo: { masarriba },
    video: { masarribavid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },  
  {
    id: 2,
    name: "Feeling good",
    photo: { feelinggood },
    video: { fgvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 19,
    name: "СИЯЙ",
    photo: { siyai },
    video: { siyaivid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },  
  {
    id: 17,
    name: "DISCO",
    photo: { disco },
    video: { discovid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },  
  {
    id: 18,
    name: "DRAGON",
    photo: { dragon },
    video: { dragonvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 16,
    name: "DIVA",
    photo: { diva },
    video: { divavid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  }, 
  {
    id: 4,
    name: "ASIA",
    photo: { asia },
    video: { asiavid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 5,
    name: "NOGI",
    photo: { nogi },
    video: { nogivid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 6,
    name: "RIO",
    photo: { rio },
    video: { riovid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 7,
    name: "PUSSYCAT DOLLS",
    photo: { pussycat },
    video: { pussycatvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 8,
    name: "NEON",
    photo: { neon },
    video: { neonvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 9,
    name: "CAN-CAN",
    photo: { cancan },
    video: { cancanvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  
  {
    id: 11,
    name: "DESIRE",
    photo: { desire },
    video: { desirevid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 12,
    name: "MARUV",
    photo: { maruv },
    video: { maruvvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 13,
    name: "RUSSIAN SHOW",
    photo: { russianshow },
    video: { russianshowvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
  {
    id: 14,
    name: "BUMP IT!",
    photo: { bumpit },
    video: { bumpitvid },
    desription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sollicitudin velit sit amet tortor commodo consectetur. Mauris ut felis leo. Nunc facilisis augue at ornare fringilla. Vestibulum in lectus sit amet quam ultrices facilisis sit amet imperdiet odio. Mauris iaculis nulla leo. Etiam placerat, lacus nec pharetra posuere, metus magna efficitur nibh, non aliquet velit dui sit amet tortor. Suspendisse varius risus non tortor ultrices, congue pulvinar quam porta. Phasellus ullamcorper imperdiet fringilla. Fusce non commodo nisi. Nam porttitor condimentum enim, in suscipit orci egestas eget. Mauris malesuada leo metus, at rutrum orci dictum a. Nulla sit amet imperdiet ante.",
  },
];
