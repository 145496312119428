import React from "react";
import Categories from "../components/Categories/Categories";
import Contacts from "../components/Contacts/Contacts";
import Description from "../components/Description/Description";
import Title from "../components/UI/Title/Title";

const MainPage = () => {
  return (
    <div>
      <Description />
      <Title >Форматы работы:</Title>
      <Categories />
      <Contacts />
    </div>
  );
};

export default MainPage;
