import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ApplicationPage from "../pages/ApplicationPage";
import ContactsPage from "../pages/ContactsPage";
import DancePage from "../pages/DancePage";
import MainPage from "../pages/MainPage";
import DancesPage from "../pages/DancesPage";
import CategoriesPage from "../pages/CategoriesPage";
import Thanks from "../pages/Thanks";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/main" element={<MainPage />} />
      <Route path="/categories/:name" element={<CategoriesPage />} />
      <Route path="/*" element={<Navigate to="/main" replace />} />
      <Route path="/dance/:name" element={<DancePage />} />
      <Route path="/dances/" element={<DancesPage />} />
      <Route path="/contacts" element={<ContactsPage />} />
      <Route path="/application" element={<ApplicationPage />} />
      <Route path="/thanks" element={<Thanks />} />
    </Routes>
  );
};

export default AppRouter;
