import React from 'react'
import s from './Description.module.css'
const Description = (props) => {
  return (
    <div className={s.mainText}>
      NOGI | DANCE PROJECT <br />
      Уникальный международный танцевальный проект <br />
      Сочетаем современное видение шоу-балета с классическими постановками 
      <br /> выходим за пределы России и стандартных взглядов на танец
      Профессиональные танцовщицы, многолетний опыт выступлений на российских и
      международных площадках, современные костюмы и стильная хореография.
      <br />
      Лучше один раз увидеть, чем 100 раз услышать и прочитать.
      <br /> Увидимся на твоём мероприятии.
    </div>
  );
}

export default Description