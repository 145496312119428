import React, { useEffect, useRef } from "react";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";
import Textarea from "../UI/Textarea/Textarea";
import s from "./Application.module.css";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const Application = (props) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [desription, setDescription] = useState("");
  const [disabled, setDisabled] = useState(true)
  const [phoneDirty, setPhoneDirty] = useState("")
 
  const router = useNavigate()
  const form = useRef()

  const sendForm = (e) => {
    e.preventDefault();
    setDisabled(true)
    emailjs.sendForm(
      "service_sda55aj",
      "template_53fl1ko",
      form.current,
      "1EjGRMIrWxqQs-iOY"
    )
    .then((result) => {
      setName("");
      setDescription("");
      setNumber("");
      router(`/thanks`);
      setDisabled(true)
    }, (error) => {
      console.log(error.text);
      setDisabled(true);
    });
    
  };
  const NameFunc = (e) => {
    setName(e.target.value);
  };
  const NumberFunc = (e) => {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    setNumber(e.target.value);
    if (!re.test(String(e.target.value))){
      setPhoneDirty('Некорректный номер')
    } else {
      setPhoneDirty('')
    }
  };
  const DescriptionFunc = (e) => {
    setDescription(e.target.value);
  };
  useEffect(()=>{
    if (!phoneDirty && !name && !number) {
      setDisabled(false);
    }
  }, [name, number])

  return (
    <form
      style={{ background: "transparent" }}
      className={s.form}
      ref={form}
      method="POST"
      onSubmit={sendForm}
    >
      <Input
        name="user_name"
        className={s.Inp}
        onChange={NameFunc}
        value={name}
        type="text"
        placeholder="Имя"
        required
      />
      <Input
        name="user_phone"
        onChange={NumberFunc}
        value={number}
        type="text"
        placeholder="Номер телефона"
        required
      />
      <div className={s.message}>{phoneDirty}</div>
      <Textarea
        name="user_description"
        onChange={DescriptionFunc}
        value={desription}
        placeholder="Описание (по желанию)"
      ></Textarea>
      <Button type="submit" disabled = {disabled}>
        Оставить заявку
      </Button>
    </form>
  );
};

export default Application;
