import React from "react";
import Title from "../components/UI/Title/Title";
import Button from "../components/UI/Button/Button";
import { useNavigate } from "react-router-dom";

const Thanks = () => {
  const router = useNavigate()  
  return (
    <div>
      <Title>Спасибо за заявку, мы с вами обязательно свяжемся</Title>
      <div className="butBack">
        <Button onClick={() => router(`/main`)}>
          Вернуться назад
        </Button>
      </div>
    </div>
  );
};

export default Thanks;