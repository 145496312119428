import React from 'react';
import DancesBlock from '../components/Dances/DancesBlock/DancesBlock';
import Title from '../components/UI/Title/Title';
import Contacts from '../components/Contacts/Contacts';

const DancesPage = () => {
  return (
    <div>
      <Title>Шоу-программа</Title>
      <DancesBlock />
      <Contacts />
    </div>
  );
}

export default DancesPage