import React, { useRef, useState } from "react";
import ModalWindow from "../../UI/ModalWindow/ModalWindow";
import s from "./DanceCard.module.css";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";
import New from "../New/New";

const DanceCard = (props) => {
  const ref = useRef()
  const [modal, setModal] = useState(false);
  return (
    <div>
      <div ref={ref} className={s.card} onClick={() => setModal(true)}>
        {props.new ? <New /> : <div></div>}
        <img className={s.image} src={Object.values(props.image)[0]} alt="" />
        <p className={s.name}>{props.name}</p>
      </div>
      <ModalWindow visible={modal} setVisible={setModal}>
        {modal ? <VideoPlayer playing = {true} video={Object.values(props.video)[0]} /> : <></>}
      </ModalWindow>
    </div>
  );
};

export default DanceCard;
