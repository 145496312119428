import React from "react";
import s from "./ModalWindow.module.css";
import { IoClose } from "react-icons/io5";
const ModalWindow = ({ children, visible, setVisible }) => {
  const rootClasses = [s.Modal];
  if (visible) {
    rootClasses.push(s.active);
  }
  return (
    <div className={rootClasses.join(" ")} onClick={() => setVisible(false)}>
      <div className={s.ModalContent} onClick={(e) => e.stopPropagation()}>
        {children}
        <div className={s.closeBtn} onClick={() => setVisible(false)}>
          <IoClose color="#fff" className={s.navBtn} />
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
